<template>
  <v-dialog v-model="dialog" max-width="586px" @keydown.esc="cancel" persistent>
    <v-form ref="form" class="pa-6 text-start">
      <div
        :class="{
          'd-flex justify-space-between mb-6 align-center': $vuetify.breakpoint.mdAndUp,
          'd-flex flex-column align-center': $vuetify.breakpoint.sm,
        }"
      >
        <div class="align-self-center font-weight-medium">Wallet address</div>
        <div :class="$vuetify.breakpoint.mdAndUp ? '' : 'mt-2 mb-4'">
          {{ winner.address }}
        </div>
      </div>
      <div>
        Amount
        <v-text-field
          :disabled="loading"
          placeholder="Amount"
          outlined
          dense
          v-model="amount"
          class="mt-1 rounded-xl text-body-2"
          :rules="[$rules.required]"
        >
        </v-text-field>
      </div>
      <div class="d-flex justify-space-between">
        <v-btn outlined rounded min-width="100px" @click="dialog = false">Cancel</v-btn>
        <v-btn class="primary" rounded min-width="100px" @click="save" :loading="loading">Save</v-btn>
      </div>
    </v-form>
  </v-dialog>
</template>
      
<script>
import { mapState, mapActions } from 'vuex'
const { FixedNumber } = require('@ethersproject/bignumber')
export default {
  props: {
    state: Boolean,
    winner: Object,
  },
  components: {},
  computed: {
    ...mapState('claimDetailByOwner', ['selectedWinner']),
    ...mapState('auth', ['multiClaimHandler']),
  },
  methods: {
    ...mapActions('claimPools', ['addClaim', 'resetClaimInfo']),
    async save() {
      try {
        this.loading = true
        if (!this.$refs.form.validate()) return
        const res = await this.multiClaimHandler.addWinnerList(
          this.winner.claimId,
          [this.winner.address],
          [FixedNumber.from(this.amount)],
          this.winner.decimals
        )
        if (res) {
          this.$emit('updateWalletDone', this.amount)
          this.$alert.success('Update wallet address successed')
        } else {
          this.$alert.error('Update wallet address failed')
        }
        this.dialog = false
      } catch (e) {
        this.$alert.error(e.message)
      } finally {
        this.loading = false
      }
    },
    async resetDefault() {
      if (this.$refs.form && !this.loading) {
        await this.$refs.form.reset()
      }
      if (!this.loading) this.amount = this.winner.amount
    },
  },
  created() {
    this.resetDefault()
  },
  data() {
    return {
      amount: '',
      dialog: false,
      loading: false,
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    state(state) {
      this.dialog = true
      this.resetDefault()
    },
  },
}
</script>